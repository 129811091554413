import React from "react"
import { useSelector } from "react-redux"

const Banner = () => {
  const { language } = useSelector(state => state.global)

  const banner = useSelector(({ clients }) => clients.banner)

  console.log(banner)

  return (
    <div className="banner about_banner client_banner">
      <div className="container">
        <div className="banner_txt">
          <div>
            <div className="light img-fluid">
              <img src={banner.img.sourceUrl} alt="hero" />
            </div>
            <h1 className="split-text">{banner.title[language]}</h1>
            <p className="split-text">
              <span className="banner_txtclr">
                {banner.highlightedText[language]}
              </span>
              {banner.text[language]}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
