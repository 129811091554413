import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ClientPageContent from "../components/Clients"
import { StaticQuery, graphql } from "gatsby"
import {
  CLIENT_PAGE_QUERY_REQUEST,
  CLIENT_PAGE_QUERY_SUCCESS,
} from "../store/actionTypes"
import { useDispatch } from "react-redux"
const Clients = () => {
  const dispatch = useDispatch()
  const query = graphql`
    {
      wdwordpress {
        page(id: "cGFnZToyMzQ=") {
          clients_contents {
            banner {
              img {
                title
                sourceUrl
              }
              highlightedText {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
            clients {
              list {
                link
                img {
                  sourceUrl
                }
                industry {
                  en
                  sv
                }
                text {
                  en
                  sv
                }
                anchorText {
                  en
                  sv
                }
              }
              paragraph {
                en
                sv
              }
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={({ wdwordpress }) => {
        dispatch({ type: CLIENT_PAGE_QUERY_REQUEST })
        dispatch({ type: CLIENT_PAGE_QUERY_SUCCESS, payload: wdwordpress })
        return (
          <Layout>
            <Seo title="Clients" pageId="cGFnZToyMzQ=" />
            <ClientPageContent />
          </Layout>
        )
      }}
    />
  )
}

export default Clients
